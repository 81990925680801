export function Navbar(){

        // return(
//             <div className="w-[400px] h-[60px] bg-slate-900/40 mx-auto border border-white fixed left-1/2 translate-x-[-50%] flex flex-row justify-evenly items-center">
// <a className="text-white hover:bg-white hover:text-black h-full">Home</a>
// <a className="text-white">About</a>
// <a className="text-white">Skills</a>
// <a className="text-white">Projects</a>



//             </div>
        // )
}