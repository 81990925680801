// import photo from './3d type gif.gif'
import CustomCursor from './CustomCursor';
// import { gsap } from "gsap";
import React,{useState} from 'react'
const  proj1 =  require( './asdf.mov')
const  proj2 =  require( './asdff.mov')
const  proj3 =  require( './asdfff.webp')
const proj1img = require('./proj1img.webp')
const proj2img = require('./proj2img.webp')

export function Projects(){

    const [isProj1Loaded,setProj1Loaded] = useState(false)
    const [isProj2Loaded,setProj2Loaded] = useState(false)




    return(
        <section className="w-full h-fit flex flex-col justify-center items-center">
            <CustomCursor/>
            <h1 aria-label="PROJECTS"  class="mb-10  text-[40px] font-bold  text-[#e4ded7] sm:text-[45px] mt-10 md:mb-16 md:mt-16 md:text-[60px] lg:text-[80px]"><span class="inline-block whitespace-nowrap mr-[20px]"><span aria-hidden="true" class="inline-block mr-[0.001em]">PROJECTS</span></span></h1>
        
            <div className='flex flex-wrap gap-16 lg:gap-32 w-[94vw]  justify-center '> 

                <div className='flex lg:flex-row-reverse flex-wrap justify-center lg:justify-around w-full flex-column items-center gap-3'>
                    <div className='w-[80vw] lg:w-[40vw] flex-shrink-0 text-center'>
                        <h2 className='text-blue-300 text-xl sm:text-2xl md:text-3xl lg:text-5xl break-words mt-4 border rounded-lg '><a aria-label="button" rel="noopener noreferrer"  target='_blank' href="https://uditjain.vercel.app/" className='hover:text-red-500'>Learning Management System</a></h2>
                        <p className='text-white text-2xl mt-5 lg:block hidden '>A site builder where coachings/teachers can launch there
                        purchasable courses for their students and manage their learnings</p>
                    </div>
                    <div className='w-[80vw] lg:w-[50vw] '>

                    
                    {isProj1Loaded ? (<>null</>):(<img src={proj1img} alt='project1 static' className='border rounded-lg'></img>)}
                    <video src={proj1}  alt='project1' autoplay="true"  loop onLoadedData={()=>{setProj1Loaded(true)}} className={`border rounded-lg ${isProj1Loaded ? '' : 'hidden'}`} muted></video>


                    </div>

                </div>

                <div className='flex lg:flex-row flex-wrap justify-center lg:justify-around w-full flex-column items-center gap-3'>
                    <div className='w-[80vw] lg:w-[40vw] flex-shrink-0 '>
                        <h2 className='text-blue-300 text-xl sm:text-2xl md:text-3xl  lg:text-5xl break-words mt-4 border rounded-lg '><a aria-label="button" rel="noopener noreferrer"  target='_blank' href="https://skribllio-1deg.onrender.com/" className='hover:text-red-500'>Online Real Time Multiplayer Game</a></h2>
                        <p className='text-white text-2xl mt-5 lg:block hidden'>
                        A online multiplayer game , which has whiteboard sharing system
where one user draws on which and is shared , with a chat system for
players to interact with each other.
                        </p>
                    </div>
                    <div className='w-[80vw] lg:w-[50vw]'>
                    {isProj2Loaded ? (<>null</>):(<img src={proj2img} alt='project2 static' className='border rounded-lg'></img>)}

                    <video src={proj2}  alt='project2' autoplay="true" muted onLoadedData={()=>{setProj2Loaded(true)}} className={`border rounded-lg ${isProj2Loaded ? '' : 'hidden'}`} loop></video>
                    </div>
                </div>

                <div className='flex lg:flex-row-reverse flex-wrap justify-center lg:justify-around w-full flex-column items-center gap-3'>
                    <div className='w-[80vw] lg:w-[40vw] flex-shrink-0 '>
                        <h2 className='text-blue-300 text-xl sm:text-2xl md:text-3xl  lg:text-5xl break-words mt-4 border rounded-lg '><a aria-label="button" rel="noopener noreferrer" target='_blank' href="https://goforgold.in/" className='hover:text-red-500'>Site made for Nation level Event</a></h2>
                        <p className='text-white text-2xl mt-5 lg:block hidden'>
                        Site Made for An Competitive Programming Event Which is to be led by International Grandmasters of CodeForces coming from all over the world who are world finalist's having great achievements
                        </p>
                    </div>
                    <div className='w-[80vw] lg:w-[50vw]'>
                    <img src={proj3}  alt='project3' className='border rounded-lg w-full'></img>
                    </div>
                </div>

            </div>
            <div></div>
        </section>
    )
}
