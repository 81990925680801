// CustomCursor.js
import React, { useRef, useEffect} from 'react';
import gsap from 'gsap';

const CustomCursor = () => {
  const cursorRef = useRef(null);
  useEffect(() => {

    const moveCursor = (e) => {
      gsap.to(cursorRef.current, {
        x: e.clientX,
        y: e.clientY,
        duration:0.4,
      });
    };

    const enlargeCursor = () => {
      gsap.to(cursorRef.current, { scale: 1.37, duration: 0.2, backgroundColor:"white"});
    };
    const normalCursor = () => {
      gsap.to(cursorRef.current, { scale: 1.15, duration: 0.2, backgroundColor:"white"});
    };
    const resetCursor = () => {
      gsap.to(cursorRef.current, { scale: 1, duration: 0.2, backgroundColor:"transparent"});
    };

    document.addEventListener('mousemove', moveCursor);
    document.addEventListener('mouseleave', resetCursor);

    const images = document.querySelectorAll('img');
    images.forEach((img) => {
      img.addEventListener('mouseenter', normalCursor);
      img.addEventListener('mouseleave', resetCursor);
      img.addEventListener('mousedown', enlargeCursor);
      img.addEventListener('mouseup', normalCursor);
    });

    const svgs = document.querySelectorAll('svg');
    svgs.forEach((svg) => {
      svg.addEventListener('mouseenter', normalCursor);
      svg.addEventListener('mouseleave', resetCursor);
      svg.addEventListener('mousedown', enlargeCursor);
      svg.addEventListener('mouseup', normalCursor);
    });
    const videos = document.querySelectorAll('video');
    videos.forEach((vdos) => {
      vdos.addEventListener('mouseenter', normalCursor);
      vdos.addEventListener('mouseleave', resetCursor);
      vdos.addEventListener('mousedown', enlargeCursor);
      vdos.addEventListener('mouseup', normalCursor);
    });

    return () => {
      document.removeEventListener('mousemove', moveCursor);
      document.removeEventListener('mouseleave', resetCursor);

      images.forEach((img) => {
        img.removeEventListener('mouseenter', normalCursor);
        img.removeEventListener('mouseleave', resetCursor);
        img.removeEventListener('mousedown', enlargeCursor);
        img.removeEventListener('mouseup', normalCursor);
      });

      svgs.forEach((svg) => {
        svg.removeEventListener('mouseenter', normalCursor);
        svg.removeEventListener('mouseleave', resetCursor);
        svg.removeEventListener('mousedown', enlargeCursor);
        svg.removeEventListener('mouseup', normalCursor);
      });

      videos.forEach((vdos) => {
        vdos.removeEventListener('mouseenter', normalCursor);
        vdos.removeEventListener('mouseleave', resetCursor);
        vdos.removeEventListener('mousedown', enlargeCursor);
        vdos.removeEventListener('mouseup', normalCursor);
      });
    };
  }, []);

  return (
    <div
      ref={cursorRef}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '80px',
        height: '80px',
        backgroundColor: 'transparent',
        borderRadius: '50%',
        pointerEvents: 'none',
        zIndex: 9999,
        transform: 'translate(-50%, -50%)',
        mixBlendMode: 'difference',
      }}
      className='hidden sm:block'
    ></div>
  );
};

export default CustomCursor;
