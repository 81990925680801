import React from 'react'
import {useState} from 'react'
import emailjs from '@emailjs/browser';
export function Contact(){

    const SERVICE_ID = "service_id_udit_jain"
    const TEMPLATE_ID = "template_id_udit_jain"

    const [mailData, setMailData] = useState({

        mail:'',
        msg:''
})


    function handleSubmit(){
        const options = {
            publicKey: 'BvQB1gQjOXs-oZVoV',
            // Do not allow headless browsers
            blockHeadless: true,
            blockList: {
              // Block the suspended emails
              list: ['foo@emailjs.com', 'bar@emailjs.com'],
              // The variable contains the email address
              watchVariable: mailData.mail,
            },
            limitRate: {
              // Set the limit rate for the application
              id: 'app',
              // Allow 1 request per 10s
              throttle: 10000,
            },
          }
        emailjs.init(options);

        if(mailData.mail.trim && mailData.msg.trim){
            emailjs.send(SERVICE_ID, TEMPLATE_ID, {name:mailData.mail,notes:mailData.msg}, options).then(
                (response)=>{
                    console.log('SUCCESS!', response.status, response.text)
                    alert("Message Sent Successfully!")
                    setMailData({mail:'',msg:''})
                }
            )
        }
    }
    const handleChange = (e)=>{
        const { name, value } = e.target
        setMailData({
            ...mailData,
            [name]: value
        });
    }
     
    

    return(
        <footer id="contact" className="w-full h-screen flex flex-col justify-center">




            <div className="flex flex-col-reverse md:flex-row gap-12 items-center mt-72">

                <div>
                    
                </div>

                <div className="flex flex-col  gap-4 w-[80vw] md:w-[50vw] ">                
                <div className="text-white text-lg items-center" aria-description='Udit Jain Lives in Sonipat|Udit Jain lives in Hisar|Udit Jain NST|Udit Jain Full Stack Developer'>You can contact me through <a rel="noopener noreferrer" aria-label="Mail Link" href="mailto:uditj668@gmail.com"><u>uditj668@gmail.com</u></a> or via this form</div>

                <div>
                    <input name="mail" className="bg-zinc-700/80 text-zinc-200  px-6 w-[80vw] md:w-[50vw] rounded-lg h-16 " value={mailData.mail} onChange={handleChange} placeholder="Your Email"></input>
                </div>

                <div>
                    <textarea name="msg" className="bg-zinc-700/80 text-zinc-200 py-4 px-6 w-[80vw] md:w-[50vw] rounded-lg h-72" value={mailData.msg} onChange={handleChange}  placeholder="Your Message"></textarea>
                </div>
                <div >
                    <button onClick={handleSubmit} className="w-36 h-[3.5rem] hover:border-zinc-200 hover:scale-105 font-light text-zinc-200 border border-zinc-200/50 rounded-full items-center justify-center gap-3 text-xl flex flex-row">
                    <span>Submit</span>
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="text-xs opacity-70 transition-all  lg:group-hover:translate-x-0.5 lg:group-hover:-translate-y-0.5 " height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"></path></svg>
                    </button>
                    
                </div>
                </div>

                <div className="w-[80vw] text-2xl sm:text-3xl md:text-4xl md:w-[38vw] mx-auto">


                    <h3 className="text-white  font-light leading-tight text-center">Do you have some <span className="font-bold">Project</span>, <span className="font-bold">Suggestion</span> or <span className="font-bold">Feedback</span>.</h3>
                    <h3 className="text-white  font-light text-center"> I would love to hear from you.</h3>
        <div className='flex flex-col justify-center items-center gap-2 mt-12 animate-fall '>

            <div className='flex flex-row mt-10 h-16 gap-6 '>
            <a target="_blank" href='https://linkedin.com/in/uditjain13' rel="noopener noreferrer" aria-label="LinkedIn Link" id="socials" class="flex p-6 items-center gap-2  text-gray-700 rounded-full   lg:hover:scale-[1.25] transition  group border lg:group-hover:bg-custom-gradient  border-black/10 bg-white/15 text-white/60"> <span class=""><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="0.5em" xmlns="http://www.w3.org/2000/svg"><path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"></path></svg></span></a>
            <a target='_blank' href='https://github.com/uditjainstjis' rel="noopener noreferrer" aria-label='Github Link' id="socials"  class="flex p-6 items-center gap-2  text-gray-700 rounded-full  lg:hover:scale-[1.25] transition  group border lg:group-hover:bg-custom-gradient  border-black/10 bg-white/15 text-white/60"> <span class=""><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="0.5em" xmlns="http://www.w3.org/2000/svg"><path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"></path></svg></span></a>
            <a href='mailto:uditj668@gmail.com' id="socials"  className="  flex items-center gap-2 p-4 text-gray-700 rounded-full  lg:group-hover:scale-[1.25] p-6 transition  group border lg:hover:scale-[1.25] bg-custom-gradient  border-black/10 bg-white/15 text-white/60"> <span class=""><img  alt="linkedIn" className='h-3 ' src='https://static.vecteezy.com/system/resources/thumbnails/023/989/331/small/business-icon-mail-with-transparent-background-free-png.png'></img></span></a>
            
            </div> 
            </div>        
                </div>
                

            </div>
            <div className="w-[30vw] h-[20vh] mt-20 right-0  absolute bg-[#c8c1f3] blur-[15rem] "></div>

            <div className=" mt-20 md:mt-40">
            <div className="w-[40vw] h-[10vh] right-0 mb-40  absolute bg-[#c8c1f3] blur-[13rem] "></div>

                <h3 className='text-zinc-400 text-center'>© Udit Jain. All rights reserved.</h3>
                
                        <h3 className='text-zinc-400 text-center'>This website is built using: Reactjs, Threejs, JavaScript, Emailjs,GSAP ,Lenis , Tailwind CSS</h3>

                    </div>
                


        </footer>
    )
}
